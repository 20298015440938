<template>
  <div>
    <!-- Custom Side Bar -->
    <SideBar />
    <!-- Custom App Bar -->
    <AppBar />
    <!-- Sizes your content based upon application components -->
    <v-content>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- Custom Confirm Dialog -->
        <confirm-dialog></confirm-dialog>
        <!-- Provides content that integrated with vue router -->
        <transition name="slide-fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-content>
  </div>
</template>

<script>
const SideBar = () => import("@/containers/SideBar");
const AppBar = () => import("@/containers/AppBar");
// import store from "@/store";

export default {
  name: "Container",

  components: {
    SideBar,
    AppBar
  }
};
</script>
